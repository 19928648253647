<template>
    <fault-manage :typePage="typePage"></fault-manage>
</template>

<script>
import FaultManage from "@/views/modules/collection/business/fault/faultManage.vue";

export default {
    name: "faultRecord",
    components: {FaultManage},
    data(){
        return{
            typePage:'faultRecord',
        }
    },
}
</script>

<style scoped>

</style>